import '/assets/styles/modals/auth.scss';
import { useState, useMemo, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

// Partials
import { Form, Input, FormFeedback } from '/views/partials/forms';
import Btn from '/views/partials/btn'
import PinInput from '/views/partials/pin-input'
import Img from '@wearetla/tla-essentials-tools/partials/img';

// Functions
import formatPhone from '@wearetla/tla-essentials-tools/functions/format-phone';

// Services
import userServices from '/services/user';

// Context
import { useAuth } from '/utilities/auth';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';

// Static Assets
import image_banner from '/assets/images/auth-modal/banner.jpg';
import image_banner_2x from '/assets/images/auth-modal/banner@2x.jpg';

const modalClasses = 'modal-auth mobile-full';

// Enums
const modes = {
	login: 'login',
	register: 'register',
	intro: 'intro',
}

const AuthContext = createContext({
	submitting: false,
	mode: modes.login,
	formData: null,
	setMode: () => {
		console.log('Auth Context not set up.');
	},
	submit: () => {
		console.log('Auth Context not set up.');
	}
});

const useAuthModal = () => (useContext(AuthContext));

// Assets
const AuthModal = ({ closeBtn, close, setContainerClass }) => {
	const { desktop } = useBreakpoints();
	const { softLogin } = useAuth();
	const [mode, setMode] = useState(desktop ? modes.login : modes.intro);
	const [formData, setFormData] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [feedbackData, setFeedbackData] = useState(null);

	// Pin
	const [pin, setPin] = useState('');
	const [otpSent, setOtpSent] = useState(false);
	const [pinError, setPinError] = useState(false);

	const isOtp = useMemo(() => !!formData?.phone && otpSent, [formData, otpSent]);
	const maskedPhone = useMemo(() => {
		if(formData?.phone) {
			const formattedPhone = formatPhone(formData.phone);
			return `${formattedPhone.substring(0, 9)}**${formattedPhone.substring(11, formattedPhone.length)}`;
		}
		return null;
	}, [formData])

	useEffect(() => {
		setFormData(null);
		setFeedbackData(null);
		setContainerClass(`${modalClasses} mode-${mode}`);
	}, [mode])
	
	const sendOtp = (phone, paramMode) => {
		return new Promise((resolve, reject) => {
			setSubmitting(true);
			setFeedbackData(null);
			userServices.sendOTP(phone ?? formData?.phone, paramMode ?? mode).then(() => {
				setPin('');
				setSubmitting(false);
				setOtpSent(true);
				resolve(true);
			}).catch((error) => {
				setFeedbackData(error);
				setSubmitting(false);
				reject(error);
			});
		});
	}

	const submitOtp = () => {
		if(pin.length === 6) {
			setSubmitting(true);
			setPinError(false);
			
			userServices.checkOTP(formData.phone, pin).then((token) => {
				if(formData.mode === 'register') {
					const registerData = {
						...omit(formData, ['mode', 'user_term', 'kvkk_term']),
						otp_token: token,
						email_notify: formData.email_notify === '1',
						sms_notify: formData.sms_notify === '1',
						push_id: null,
						agreements: [
							'KVKK_TERM',
							'USER_TERM'
						]
					};

					userServices.register(registerData).then((payload) => {
						softLogin(payload.access_token).then(() => {
							close();
						}).catch((error) => {
							setFeedbackData(error);
							setSubmitting(false);
						})
					}).catch((error) => {
						setFeedbackData(error);
						setSubmitting(false);
					})
				}
				else {
					userServices.login(formData.phone, token).then((payload) => {
						softLogin(payload.access_token).then(() => {
							close();
						}).catch((error) => {
							setFeedbackData(error);
							setSubmitting(false);
						})
					}).catch((error) => {
						setFeedbackData(error);
						setSubmitting(false);
					})
				}
			}).catch((error) => {
				setFeedbackData(error);
				setSubmitting(false);
			})

		}
		else {
			setPinError(true);
		}
	}

	const submit = (fields) => {
		sendOtp(fields.phone, fields.mode).then(() => {
			setFormData(fields);
		}).catch((error) => {
			setFeedbackData(error);
		})
	};

	return (
		<AuthContext.Provider value={{
			submit,
			submitting,
			formData,
			mode,
			feedbackData,
			setMode
		}}>
			{closeBtn}
			{desktop &&
				<div className="modal-bannerwrap">
					<Img
						cover
						src={image_banner}
						srcSet={`${image_banner_2x} 2x`}
						className="bannerwrap-banner" />
				</div>
			}
			<div className="modal-body">
				{isOtp ?
					<div className="auth-pinform">
						<strong className="auth-title">SMS Kodu</strong>
						<div className="auth-phonemessage">
							Lütfen <span>{maskedPhone}</span> numaralı telefonuzua gelen SMS kodunu aşağıdaki alana giriniz.
						</div>

						<FormFeedback className="auth-feedback" data={feedbackData} />

						<PinInput
							className="auth-pininput"
							disabled={submitting}
							error={pinError}
							onResend={sendOtp}
							onComplete={submitOtp}
							pin={pin}
							onChange={setPin} /> 

						<Btn
							disabled={submitting}
							loading={submitting}
							onClick={submitOtp}
							className="pinform-submit primary block">
							{mode === modes.login ? 'Giriş Yap' : 'Kayıt Ol'}
						</Btn>
					</div>
					:
					<>
						{mode === modes.intro &&
							<Intro />
						}
						{mode === modes.register &&
							<RegisterForm />
						}
						{mode === modes.login &&
							<LoginForm />
						}
					</>
				}
			</div>
		</AuthContext.Provider>
	)
}

const Intro = () => {
	const { setMode } = useAuthModal();
	
	return (
		<>
			<strong className="auth-title">Merhaba</strong>

			<div className="auth-intro">
				<Btn
					onClick={() => {
						setMode(modes.login);
					}}
					className="big text-big white outline block intro-optbtn">
					Giriş Yapın
				</Btn>
				<span className="intro-separator">veya</span>
				<Btn
					onClick={() => {
						setMode(modes.register);
					}}
					className="big text-big white outline block intro-optbtn">
					Üye Olun
				</Btn>
			</div>
		</>
	)
}

const LoginForm = () => {
	const { submit, setMode, submitting, feedbackData } = useAuthModal();

	const onSubmit = (fields) => {
		submit({
			mode: 'login',
			...fields,
		})
	}

	return (
		<>
			<strong className="auth-title">Giriş Yapın</strong>
			<Form
				onSubmit={onSubmit}
				className="auth-loginform grid-container">
				<FormFeedback
					inGrid
					className="auth-feedback"
					data={feedbackData} />
				<div className="row">
					<div className="col">
						<Input
							autoFocus
							disabled={submitting}
							type="phone"
							label="Telefon"
							name="phone"
							autoComplete="tel"
							validation={{
								required: 'Telefon numaranızı adresinizi girmelisiniz.',
								phone: 'Geçerli bir telefon numarası girmelisiniz.',
							}} />
					</div>
				</div>
				
				<div className="row">
					<div className="col">
						<Btn
							type="submit"
							disabled={submitting}
							loading={submitting}
							className="block primary">
							Giriş Yap
						</Btn>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="auth-nav">
							Hesabınız yok mu? Hızlıca <button onClick={() => { setMode(modes.register) }} type="button" className="hyperlink">Üye Olun</button>.
						</div>
					</div>
				</div>
			</Form>
		</>
	)
}

const RegisterForm = () => {
	const { submit, setMode, feedbackData, submitting } = useAuthModal();
	const { openModal } = useModals();

	const onSubmit = (fields) => {
		submit({
			mode: 'register',
			...fields,
		})
	}

	return (
		<>
			<strong className="auth-title">Üye Olun</strong>
			<Form
				onSubmit={onSubmit}
				className="auth-registerform grid-container">
				<FormFeedback
					inGrid
					className="auth-feedback"
					data={feedbackData} />
				<div className="row">
					<div className="col x6 m-x6">
						<Input
							disabled={submitting}
							type="text"
							label="Ad"
							name="name"
							autoComplete="given-name"
							validation={{
								required: 'Adınızı girmelisiniz.',
							}} />
					</div>
					<div className="col x6 m-x6">
						<Input
							disabled={submitting}
							type="text"
							label="Soyad"
							name="surname"
							autoComplete="family-name"
							validation={{
								required: 'Soyadınızı girmelisiniz.',
							}} />
					</div>
				</div>
				<div className="row">
					<div className="col">
						<Input
							disabled={submitting}
							type="text"
							label="E-posta"
							name="email"
							autoComplete="email"
							validation={{
								required: 'E-posta adresinizi girmelisiniz.',
								email: 'Geçerli bir e-posta adresi girmelisiniz.',
							}} />
					</div>
				</div>
				<div className="row">
					<div className="col">
						<Input
							disabled={submitting}
							type="phone"
							label="Telefon"
							name="phone"
							autoComplete="tel"
							validation={{
								required: 'E-posta adresinizi girmelisiniz.',
								phone: 'Geçerli bir telefon numarası girmelisiniz.',
							}} />
					</div>
				</div>
				<div className="row">
					<div className="col auth-agreements">
						<Input
							disabled={submitting}
							className="agreements-checkbox multi-line"
							type="checkbox"
							label={<>
								<button type="button" className="hyperlink" onClick={() => { openModal('agreement', { layer: 2, agreement: 'KVKK_TERM'}) }}>Kişisel Verilerin Korunması Hakkında Bilgilendirme metnini</button> okudum ve içeriğini anladım.
							</>}
							uncheckedValue={false}
							defaultValue="1"
							name="kvkk_term"
							validation={{
								required: 'KVKK metnini onaylamalısınız.',
							}} />
						
						<Input
							disabled={submitting}
							className="agreements-checkbox multi-line"
							type="checkbox"
							label={<>
								<button type="button" className="hyperlink" onClick={() => { openModal('agreement', { layer: 2, agreement: 'USER_TERM'}) }}>Üyelik Sözleşmesi metnini</button> okudum ve içeriğini anladım.
							</>}
							uncheckedValue={false}
							defaultValue="1"
							name="user_term"
							validation={{
								required: 'Üyelik sözleşmesini onaylamalısınız.',
							}} />

						<Input
							disabled={submitting}
							className="agreements-checkbox multi-line"
							type="checkbox"
							label="Etkinlik Habercisi ile özel promosyon ve kampanya duyurularını E-posta yoluyla almak istiyorum."
							uncheckedValue="0"
							defaultValue="1"
							name="email_notify" />

						<Input
							disabled={submitting}
							className="agreements-checkbox multi-line"
							type="checkbox"
							label="Etkinlik Habercisi ile özel promosyon ve kampanya duyurularını SMS yoluyla almak istiyorum."
							uncheckedValue="0"
							defaultValue="1"
							name="sms_notify" />
					</div>
				</div>
				<div className="row">
					<div className="col">
						<Btn
							disabled={submitting}
							loading={submitting}
							type="submit"
							className="block primary">
							Üye Ol
						</Btn>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="auth-nav">
							Hesabınız var mı? Hemen <button onClick={() => { setMode(modes.login) }} type="button" className="hyperlink">Giriş Yapın</button>.
						</div>
					</div>
				</div>
			</Form>
		</>
	)
}

AuthModal.defaultProps = {
	icon: 'info',
	wysiwyg: false,
	hideCloseBtn: true,
}

AuthModal.propTypes = {
	closeBtn: PropTypes.node,
	close: PropTypes.func,
	icon: PropTypes.string,
	title: PropTypes.string,
	options: PropTypes.array,
	message: PropTypes.string,
	wysiwyg: PropTypes.bool,
	hideCloseBtn: PropTypes.bool,
}

AuthModal.modalConfig = {
	containerClass: modalClasses,
}

export default AuthModal